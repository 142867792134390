import React from "react"
import Swiper from "react-id-swiper"
import { Pagination, Navigation } from "swiper/dist/js/swiper.esm"
import "react-id-swiper/lib/styles/css/swiper.css"

class homeSwiper extends React.Component {
	render() {
		const { children, slidesperview } = this.props
		const params = {
			modules: [Pagination, Navigation],
			slidesPerView: slidesperview,
			spaceBetween: 0,
			containerClass: "swiper-container pb-5",
			nested: true,
			landscape: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			breakpoints: {
				991: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
			},
		}
		return <Swiper {...params}>{children}</Swiper>
	}
}

export default homeSwiper
