import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import TeamNetwork from "../components/svg/TeamNetwork2"
import WhyChoose from "../components/section/WhyChoose"
// images
// import JDS from "../images/jds.png"
// import PEER from "../images/peersource-icon.png"
// import OnePoint from "../images/onepoint.png"
import PN from "../images/people-network.png"
import Crosshand from "../images/crosshand.png"
import PeernetworkDiagram from "../images/peernetwork-diagram.svg"

const PeerNetwork = data => {
  return (
    <Layout title="PeerNetwork">
      <SEO
        title="PeerNetwork"
        description="Simply put, it’s a network of professionals and business services that enables us to provide the best possible service to our candidates and clients."
      />
      <div id="pn-page">
        <div className="bg-blue pb-100">
          <Container>
            <div className="row justify-content-center network">
              <Col lg={10} className="mx-auto">
                <h1 className="peernetwork-heading text-white text-center">
                  PeerNetwork
                </h1>
                <div className="py-4">
                  <TeamNetwork />
                </div>
              </Col>
              <Col lg={12}>
                <h2 className="text-white text-center mb-3">
                  What is the PeerNetwork?
                </h2>
              </Col>
              <Col lg={6}>
                <p className="lead text-white text-center">
                  Simply put, it's a network of professionals and business
                  services that enables us to provide the best possible service
                  to our candidates and clients.
                </p>
              </Col>
            </div>
          </Container>
        </div>
        <Container style={{ padding: "100px 0" }} className="text-center">
          {/* <Network /> */}
          <img
            className="w-75 img-fluid mt-4"
            src={PeernetworkDiagram}
            alt=""
          />
        </Container>
        <WhyChoose />
        <div className="py-100">
          <Container>
            <Row>
              <Col lg={7}>
                <h2>PeerNetwork Companies</h2>
                <p>
                  Technology recruiting isn't the only service PeerSource
                  provides. In partnership with our affiliated sister
                  companies, we are able to offer so much more.
                </p>
                <h5>Nextaff</h5>
                <ul>
                  <li>
                    Healthcare and Office/Clerical Recruiting: We provide you
                    with reliable CNA/LPN/RN and Office/Clerical talent to help
                    fill your needs.
                  </li>
                </ul>

                <h5>OnePoint HRO</h5>
                <ul>
                  <li>
                    Payroll: We can help you process your payroll including tax
                    deposits, direct deposit, etc.
                  </li>
                  <li>
                    Comprehensive HR Services: In addition to the above payroll
                    services, our PEO offers excellent benefits to you and your
                    employee including 12 different health insurance plans,
                    dental, vision, life, and 401k. Comprehensive HR for the
                    employer.
                  </li>
                </ul>

                <h5>JDS Professional Group</h5>
                <ul>
                  <li>
                    Accounting/Financial: We have a full-service public
                    accounting firm that can help you with tax, audit, and
                    virtual accounting resources.
                  </li>
                </ul>
              </Col>
              <Col lg={5} className="align-self-center">
                <img
                  src={Crosshand}
                  className="img-fluid mb-5"
                  alt="Crosshand"
                />
                <img src={PN} className="img-fluid" alt="PN" />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="py-50 bg-blue text-center">
          <Container>
            <Col md={10} className="mx-auto">
              <a
                className="white-btn border-none"
                href="tel:303-867-1111"
                style={{ fontSize: "20px" }}
              >
                Call Today
              </a>
            </Col>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default PeerNetwork
