import React from "react"
import MySwiper from "../swiper/homeSwiper"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
const WhyChoose = () => {
  return (
    <div id="why-choose" className="py-100">
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 6 }}>
            <div className="bg-tp">
              <h3 className="text-uppercase position-relative">
                WHY CHOOSE PEERSOURCE?
              </h3>

              <MySwiper slidesperview={1}>
                <div className="choose-review">
                  <p>
                    PeerSource spends the time getting to know me and my
                    organization to quickly understand what I need. They found
                    highly qualified individuals who were not just technically
                    strong, but more importantly were a culture fit for our
                    organization. Not only did they meet my needs, but they also
                    found a way to ensure they were meeting the candidates'
                    needs. They truly care about people and they made sure that
                    the culture and opportunity was just as much of a fit for
                    them as the candidate was a fit for my team. I highly
                    recommend them to both clients and candidates who have a
                    need to either place or be placed.
                  </p>
                  <h6>
                    - Wendy Jones,{" "}
                    <span className="font-weight-normal">
                      VP of ePMO/VP of Enterprise Applications
                    </span>
                  </h6>
                </div>
                <div className="choose-review">
                  <p>
                    My experience working as a candidate with PeerSource was
                    excellent and their recruiters were great to deal with. They
                    were very transparent, responsive, and available to me at
                    all times. I greatly enjoyed working with them and how
                    professional they were in dealing with me throughout the
                    entire process.
                  </p>
                  <h6>
                    - Glenn Dodds,{" "}
                    <span className="font-weight-normal">VP of IT</span>
                  </h6>
                </div>
                <div className="choose-review">
                  <p>
                    Working with PeerSource, I find them to be very engaging,
                    dependable and high in integrity. One of their recruiters
                    previously contacted me the year before and followed up
                    connecting me to a position within 4 days. They deliver.
                    It’s a pleasure to recommend PeerSource as an accomplished
                    recruiting firm.
                  </p>
                  <h6>
                    - Jeremy Creager,{" "}
                    <span className="font-weight-normal">
                      Senior IT Project Manager
                    </span>
                  </h6>
                </div>

                <div className="choose-review">
                  <p>
                    The team at PeerSource has always been committed to finding
                    talented, passionate, well-qualified individuals. Also,
                    they’ve taken the time to get the know the dynamics of
                    everyone on the team so they can submit candidates that will
                    also be excellent cultural additions to the group. I’ve
                    found them to be timely, thorough and committed to making
                    sure we have the best possible fit in every category. I
                    would recommend PeerSource to anyone looking for best in
                    class IT recruiting.
                  </p>
                  <h6>
                    - Brenda Simpler,{" "}
                    <span className="font-weight-normal">
                      Director of Marketing Technology
                    </span>
                  </h6>
                </div>
              </MySwiper>

              <Link
                className="common-btn"
                to="/technology/testimonials"
                style={{ zIndex: "2" }}
              >
                Learn More
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhyChoose
