import React from "react"
import "./team-network.scss"

function TeamNetwork() {
  return (
    <svg
      width="100%"
      height="auto"
      viewBox="0 0 1317 374.4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id="peernetwork-diagram"
    >
      <g id="Group_26009" transform="translate(-10496 1943)">
        <path id="Path_53" class="st0" d="M11522.1-1774.7l192.2-71" />
        <path id="Path_54" class="st0" d="M11730.8-1657.1l13.9-163.5" />
        <g id="Group_25983" transform="translate(10194 -2277)">
          <circle id="Ellipse_19" class="st1" cx="1304" cy="627.4" r="12" />
          <ellipse
            id="Ellipse_20"
            class="st1"
            cx="1083"
            cy="609.4"
            rx="15"
            ry="16"
          />
          <circle id="Ellipse_21" class="st2" cx="1246.5" cy="415.9" r="5.5" />
          <circle id="Ellipse_22" class="st3" cx="1453.5" cy="357.9" r="11.5" />
          <circle id="Ellipse_23" class="st2" cx="1606.5" cy="573.9" r="12.5" />
          <circle id="Ellipse_24" class="st4" cx="956.5" cy="397.9" r="12.5" />
          <circle id="Ellipse_25" class="st5" cx="538.5" cy="468.9" r="12.5" />
          <ellipse
            id="Ellipse_26"
            class="st5"
            cx="313.5"
            cy="650.4"
            rx="11.5"
            ry="11"
          />
          <ellipse
            id="Ellipse_27"
            class="st4"
            cx="766"
            cy="629.9"
            rx="5"
            ry="4.5"
          />
        </g>
        <path id="Path_50" class="st0" d="M10654.2-1811.6l52.1,107.2" />
        <path id="Path_51" class="st0" d="M10775.1-1660.3l131-20.6l71-11.1" />
        <path id="Path_52" class="st0" d="M10967.7-1838l217.7,21.6" />
        <g>
          <defs>
            <ellipse
              id="Ellipse_28"
              cx="10639.5"
              cy="-1847.1"
              rx="38.5"
              ry="39.5"
            />
          </defs>
          <clipPath id="Ellipse_28_1_">
            <use xlinkHref="#Ellipse_28" style={{ overflow: "visible" }} />
          </clipPath>
          <g style={{ clipPath: "url(#Ellipse_28_1_)" }}>
            <image
              style={{ overflow: "visible" }}
              width="77"
              height="79"
              xlinkHref="/assets/img/Ellipse 28.png"
              transform="matrix(1 0 0 1 10601 -1886.5865)"
            ></image>
          </g>
        </g>
        <g>
          <defs>
            <circle id="Ellipse_30" cx="10926" cy="-1842.6" r="44" />
          </defs>
          <clipPath id="Ellipse_30_1_">
            <use xlinkHref="#Ellipse_30" style={{ overflow: "visible" }} />
          </clipPath>
          <g style={{ clipPath: "url(#Ellipse_30_1_)" }}>
            <image
              style={{ overflow: "visible" }}
              width="88"
              height="88"
              xlinkHref="/assets/img/Ellipse 30.png"
              transform="matrix(1 0 0 1 10882 -1886.5865)"
            ></image>
          </g>
        </g>
        <g>
          <defs>
            <circle id="Ellipse_31" cx="11011" cy="-1696.6" r="35" />
          </defs>
          <clipPath id="Ellipse_31_1_">
            <use xlinkHref="#Ellipse_31" style={{ overflow: "visible" }} />
          </clipPath>
          <g style={{ clipPath: "url(#Ellipse_31_1_)" }}>
            <image
              style={{ overflow: "visible" }}
              width="70"
              height="70"
              xlinkHref="/assets/img/Ellipse 31.png"
              transform="matrix(1 0 0 1 10976 -1731.5865)"
            ></image>
          </g>
        </g>
        <g>
          <defs>
            <circle id="Ellipse_33" cx="11482" cy="-1760.6" r="47" />
          </defs>
          <clipPath id="Ellipse_33_1_">
            <use xlinkHref="#Ellipse_33" style={{ overflow: "visible" }} />
          </clipPath>
          <g style={{ clipPath: "url(#Ellipse_33_1_)" }}>
            <image
              style={{ overflow: "visible" }}
              width="94"
              height="94"
              xlinkHref="/assets/img/Ellipse 33.png"
              transform="matrix(1 0 0 1 11435 -1807.5865)"
            ></image>
          </g>
        </g>
        <g
          id="jds-wrapper"
          className="has-text"
          transform="translate(10678 -1750)"
        >
          <circle id="Ellipse_132" class="st6" cx="67.5" cy="79.9" r="67.5" />
          <text transform="matrix(1 0 0 1 39.3105 68.4135)">
            <tspan x="0" y="0" class="st7 st8 st9">
              JDS (tax,
            </tspan>
            <tspan x="-26.9" y="15.6" class="st7 st8 st9">
              audit, accounting,
            </tspan>
            <tspan x="-9.8" y="31.2" class="st7 st8 st9">
              and finance)
            </tspan>
          </text>
          <g id="jds-logo" className="logo">
            <defs>
              <circle id="header-1" cx="67.5" cy="79.9" r="67.5" />
            </defs>
            <use
              xlinkHref="#header_x002D_1"
              style={{ overflow: "visible", fill: "#ffffff" }}
            />
            <clipPath id="header-1_1_">
              <use xlinkHref="#header-1" style={{ overflow: "visible" }} />
            </clipPath>
            <g style={{ clipPath: "url(#header-1_1_)" }}>
              <image
                style={{ overflow: "visible" }}
                width="300"
                height="81"
                xlinkHref="/assets/img/jds.jpeg"
                transform="matrix(0.3907 0 0 0.3907 8.3907 64.089)"
              ></image>
            </g>
          </g>
        </g>
        <g
          id="peersource-wrapper"
          className="has-text"
          transform="translate(11175 -1887)"
        >
          <circle id="Ellipse_131" class="st6" cx="57.5" cy="69.9" r="57.5" />
          <text transform="matrix(1 0 0 1 22.1924 57.799)">
            <tspan x="0" y="0" class="st7 st8 st9">
              PeerSource
            </tspan>
            <tspan x="-9.8" y="15.6" class="st7 st8 st9">
              (IT staffing and
            </tspan>
            <tspan x="3.5" y="31.2" class="st7 st8 st9">
              recruiting)
            </tspan>
          </text>
          <g id="peersource-logo" className="logo">
            <defs>
              <ellipse id="Ellipse_32" cx="57.5" cy="69.4" rx="56.5" ry="57" />
            </defs>
            <use
              xlinkHref="#Ellipse_32"
              style={{ overflow: "visible", fill: "#ffffff" }}
            />
            <clipPath id="Ellipse_32_1_">
              <use xlinkHref="#Ellipse_32" style={{ overflow: "visible" }} />
            </clipPath>
            <g
              transform="matrix(1 0 0 1 0 1.907349e-06)"
              style={{ clipPath: "url(#Ellipse_32_1_)" }}
            >
              <image
                style={{ overflow: "visible" }}
                width="200"
                height="200"
                xlinkHref="/assets/img/peersource.png"
                transform="matrix(0.38 0 0 0.38 19.5 31.4135)"
              ></image>
            </g>
          </g>
        </g>
        <g
          id="onepoint-wrapper"
          className="has-text"
          transform="translate(11656 -1716)"
        >
          <circle id="Ellipse_133" class="st6" cx="67.5" cy="79.9" r="67.5" />
          <text transform="matrix(1 0 0 1 36.8728 75.9133)">
            <tspan x="0" y="0" class="st7 st8 st9">
              OnePoint
            </tspan>
            <tspan x="-16.4" y="15.6" class="st7 st8 st9">
              (PEO Services)
            </tspan>
          </text>
          <g id="onepoint-logo" className="logo">
            <defs>
              <circle id="header2" cx="67.5" cy="79.9" r="67.5" />
            </defs>
            <use
              xlinkHref="#header2"
              style={{ overflow: "visible", fill: "#ffffff" }}
            />
            <clipPath id="header2_1_">
              <use xlinkHref="#header2" style={{ overflow: "visible" }} />
            </clipPath>
            <g
              transform="matrix(1 0 0 1 0 -3.814697e-06)"
              style={{ clipPath: "url(#header2_1_)" }}
            >
              <image
                style={{ overflow: "visible" }}
                width="300"
                height="87"
                xlinkHref="/assets/img/onepoint.jpeg"
                transform="matrix(0.4042 0 0 0.4042 6.875 62.3322)"
              ></image>
            </g>
          </g>
        </g>
        <g
          id="nextaff-wrapper"
          className="has-text"
          transform="translate(10494.203 -1939)"
        >
          <circle id="Ellipse_140" class="st6" cx="1254.3" cy="76.9" r="43.5" />
          <text transform="matrix(1 0 0 1 1232.0149 63.4134)">
            <tspan x="0" y="0" class="st7 st8 st9">
              Nextaff
            </tspan>
            <tspan x="-18" y="15.6" class="st7 st8 st9">
              (employment
            </tspan>
            <tspan x="-15.7" y="31.2" class="st7 st8 st9">
              and staffing)
            </tspan>
          </text>
          <g id="nextaff_logo" className="logo">
            <defs>
              <ellipse
                id="Ellipse_34"
                cx="1254.3"
                cy="76.4"
                rx="43.5"
                ry="43"
              />
            </defs>
            <use
              xlinkHref="#Ellipse_34"
              style={{ overflow: "visible", fill: "#ffffff" }}
            />
            <clipPath id="Ellipse_34_1_">
              <use xlinkHref="#Ellipse_34" style={{ overflow: "visible" }} />
            </clipPath>
            <g style={{ clipPath: "url(#Ellipse_34_1_)" }}>
              <image
                style={{ overflow: "visible" }}
                width="300"
                height="91"
                xlinkHref="/assets/img/nextaff.png"
                transform="matrix(0.2637 0 0 0.2637 1214.7366 64.4135)"
              ></image>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TeamNetwork
